<template>
  <v-container class="main-container">
    <v-row no-gutters style="margin-top: 80px; margin-bottom: 60px">
      <h1>
        Oh nein, du scheinst noch außerhalb unseres Liefergebietes zu liegen!
      </h1>
    </v-row>

    <v-row justify="center" class="mb-15">
      <img
        :src="scooterImg"
        width="213"
        height="202"
        alt="McDelivery Lieferdienst"
        loading="lazy"
      />
    </v-row>

    <v-row no-gutters justify="center" v-if="!voted">
      <h2 class="mb-4" align="center">
        Wir möchten unser Liefergebiet erweitern – aber wo anfangen?
      </h2>
      <p class="mb-8" align="center">
        Vote jetzt für deine Nachbarschaft und hol McDelivery® zu dir!
      </p>
      <br />
      <v-col cols="12" align="center">
        <button
          class="mdButton d-block"
          @click="submitVote"
          style="margin-bottom: 10px"
        >
          Voten
        </button>
      </v-col>
    </v-row>

    <v-row v-else style="margin-bottom: 30px">
      <v-col cols="12" align="center">
        <h2 class="mb-4" align="center">
          Danke fürs Abstimmen! Die gerade von dir eingegebene Postleitzahl
          haben wir registriert. Und wer weiß, vielleicht klingeln wir auch bald
          bei dir.
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align="center">
        <button
          class="mdButton d-block"
          @click="redirectBackToHome"
          style="margin-bottom: 150px"
        >
          Zurück zur Startseite
        </button>
      </v-col>
    </v-row>

    <TeaserImage
      :imgSrc="teaser1Img"
      :imgWidth="teaserImgWidth"
      :imgHeight="teaserImgHeight"
      :withContentBox="true"
      :altTag="'McDelivery Restaurant-Finder Karte'"
      style="margin-bottom: 100px"
    >
      <h2 class="mb-2">Finde das nächstgelegene Restaurant</h2>
      <p class="mb-4">
        Wir liefern leider nicht zu dir, freuen uns aber trotzdem auf deinen
        Besuch!
      </p>
      <br />
      <a
        href="https://www.mcdonalds.com/de/de-de/restaurant-suche.html"
        class="mdButton"
        >Zum Restaurant-Finder</a
      >
    </TeaserImage>

    <TeaserImage
      :imgSrc="teaser2Img"
      :imgWidth="teaserImgWidth"
      :imgHeight="teaserImgHeight"
      :withContentBox="true"
      :altTag="'McDelivery Produktbild'"
    >
      <h2 class="mb-2">Schau dir auch unsere neuesten Deals an!</h2>
      <p class="mb-4">
        Erfahre alles über unsere aktuellesten Angebote und Aktionen.
      </p>
      <br />
      <a href="https://www.mcdonalds.com/de/de-de.html" class="mdButton"
        >Jetzt anschauen</a
      >
    </TeaserImage>
  </v-container>
</template>

<script>
import TeaserImage from "@/components/TeaserImage";
import axios from "axios";
import router from "@/router";

export default {
  name: "VotePage",
  components: {
    TeaserImage,
  },
  data: (_this) => ({
    scooterImg: _this.getImgURL("vote/McDelivery-Vote-Scooter.webp"),
    zipCodeForVoting: "",
    voted: false,
    jsonLdData: [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "McDelivery® | McDonald’s Lieferservice",
        url: "https://mcdelivery.de/",
        logo: "https://mcdelivery.de/SocialShareImg-default.png",
      },
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        image: [],
      },
    ],
  }),
  computed: {
    teaser1Img() {
      return this.$vuetify.breakpoint.xs
        ? this.getImgURL("vote/McDelivery-Vote-Teaser-01-mobile.webp")
        : this.getImgURL("vote/McDelivery-Vote-Teaser-01-desktop.webp");
    },
    teaser2Img() {
      return this.$vuetify.breakpoint.xs
        ? this.getImgURL("vote/McDelivery-Vote-Teaser-02-mobile.webp")
        : this.getImgURL("vote/McDelivery-Vote-Teaser-02-desktop.webp");
    },
    teaserImgWidth() {
      return this.$vuetify.breakpoint.xs ? 573 : 1144;
    },
    teaserImgHeight() {
      return this.$vuetify.breakpoint.xs ? 255 : 508;
    },
  },
  created() {
    this.scrollToTop();
  },
  mounted() {
    this.storeZipCodeAndClearUrl();
    this.addJsonLdToHead();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    storeZipCodeAndClearUrl() {
      if (this.$route.query.plz) {
        this.zipCodeForVoting = this.$route.query.plz;
        this.$router.replace({ query: null });
      } else {
        router.push({ path: "/" });
      }
    },
    matomoTrackingEventVote() {
      window._paq.push([
        "trackEvent",
        "Conversions",
        "Vote for Service",
        this.zipCodeForVoting,
      ]);
    },
    submitVote() {
      this.matomoTrackingEventVote();
      axios
        .post(process.env.VUE_APP_API_URL + "vote", this.zipCodeForVoting, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log(this.zipCodeForVoting)
          console.log(response.data);
          this.voted = true;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    redirectBackToHome() {
      router.push({ name: "Home" });
    },
    getImgURL(path) {
      return process.env.VUE_APP_IMG_URL + path;
    },
    getImgSchema() {
      let images = [];
      images.push({
        "@type": "ImageObject",
        contentUrl: this.scooterImg,
        width: "213",
        height: "202",
      });
      images.push({
        "@type": "ImageObject",
        contentUrl: this.teaser1Img,
        width: this.teaserImgWidth,
        height: this.teaserImgHeight,
      });
      images.push({
        "@type": "ImageObject",
        contentUrl: this.teaser2Img,
        width: this.teaserImgWidth,
        height: this.teaserImgHeight,
      });
      this.jsonLdData[1].image = images;
    },
    async addJsonLdToHead() {
      await this.getImgSchema();
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(this.jsonLdData);
      document.head.appendChild(script);
    },
    removeJsonLdFromHead() {
      document.head.querySelectorAll("script").forEach((script) => {
        if (script.type === "application/ld+json") {
          script.remove();
        }
      });
    },
  },
  beforeDestroy() {
    this.removeJsonLdFromHead();
  },
};
</script>

<style scoped>
.main-container {
  max-width: 1168px;
}
</style>
