<template>
  <v-app>
    <mcd-header-component></mcd-header-component>
    <v-main>
      <router-view />
      <mcd-footer-component></mcd-footer-component>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  mounted() {
    this.matomoTrackingEventMobileAppStoresClicked();
  },
  data: () => ({
    noSuppliersFound: false,
  }),
  methods: {
    redirectToNosupplyPage() {
      this.noSuppliersFound = true;
    },

    matomoTrackingEventMobileAppStoresClicked() {
      setTimeout(() => {
        const superUltraRoot = document.querySelector(
          "mcd-footer-component"
        ).shadowRoot;
        const appleButton = superUltraRoot.querySelector(
          "#buttonAppleAppStore"
        );
        appleButton.addEventListener("click", () => {
          window._paq.push([
            "trackEvent",
            "Conversions",
            "App Stores",
            "Apple App Store",
          ]);
          console.log("AppleStore");
        });
      }, 1000);

      setTimeout(() => {
        const superUltraRoot = document.querySelector(
          "mcd-footer-component"
        ).shadowRoot;
        const googleButton = superUltraRoot.querySelector(
          "#buttonGooglePlayStore"
        );
        googleButton.addEventListener("click", () => {
          console.log("googlePlay");
          window._paq.push([
            "trackEvent",
            "Conversions",
            "App Stores",
            "Google Play",
          ]);
        });
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.container {
  max-width: 1168px;
}
</style>
