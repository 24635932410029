<template>
  <v-container class="main-container">
    <v-row>
      <v-col class="mb-5" no-gutters justify="center">
        <h1>Dein McDelivery®</h1>
      </v-col>
    </v-row>
    <!--    <div class="teaser-and-legal-wrapper">-->
    <TeaserImage
      class="teaser-image"
      :show-legal-text="false"
      :imgSrc="mainTeaserImg"
      :imgWidth="mainTeaserImgWidth"
      :imgHeight="mainTeaserImgHeight"
      :style="$vuetify.breakpoint.smAndDown ? 'mobile' : ''"
      :altTag="'McDelivery Titelbild mit Burger und Pommes'"
      :noBorderOnMobile="true"
      :hasAdditionalCTA="false"
      :preload="true"
    >
    </TeaserImage>
    <!--      <v-row>-->
    <!--        <v-col cols="12" class="legal-text">-->
    <!--          <div >Nur für registrierte App-User vom 10.11.2022 - 04.01.2023. Solange der Vorrat reicht. Weitere Informationen zum Gewinnspiel in der App. ® 2022 Hasbro</div>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </div>-->
    <v-row
      no-gutters
      class="info-header-1"
      :class="$vuetify.breakpoint.smAndDown ? 'mobile' : ''"
    >
      <v-col cols="12" class="mb-0">
        <h3>
          McDonald's Zuhause! Jetzt Burger, Pommes und Co. liefern lassen!
        </h3>
        <span style="font-size: 12px">Bitte Mindestbestellwert beachten. </span>
      </v-col>

      <!--      <v-col cols="12" xs="12" md="6" sm="12" :style="$vuetify.breakpoint.smAndDown ? 'text-align:center' : ''">Bitte Mindestbestellwert beachten. Coca-Cola ist eine eingetragene Schutzmarke der The Coca-Cola-->
      <!--        Company. © {{ printCurrentYear() }} McDonald’s</v-col>-->
    </v-row>

    <SearchComponent
      @legalLineDataAvailable="onLegalLineDataAvailable"
      id="search"
      @noSuppliersFound="$emit('noSuppliersFound')"
      class="mb-15"
      ref="search"
    />

    <v-row no-gutters>
      <v-col
        cols="12"
        align="left"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-bottom: 80px'
            : 'margin-bottom: 100px'
        "
      >
        <h2 class="mb-8">So funktioniert McDelivery®</h2>
        <p class="mb-0">
          So einfach kannst du dir deine Bestellung an die Haustüre liefern
          lassen.
        </p>
      </v-col>
    </v-row>

    <BoxesImageText
      :boxContent="instructionStepsBoxes"
      :imgWidth="instructionImgWidth"
      :imgHeight="instructionImgHeight"
    />

    <CarouselSlider
      :slideImgs="slideImgs"
      :slideImgWidth="slideImgWidth"
      :slideImgHeight="slideImgHeight"
      :style="
        $vuetify.breakpoint.smAndUp
          ? 'margin-bottom: 100px'
          : 'margin-bottom: 30px'
      "
    />

    <BoxesImageText
      :boxContent="bottomBoxes"
      :imgWidth="boxImgWidth"
      :imgHeight="boxImgHeight"
    />
    <v-row no-gutters>
      <v-col cols="12" style="margin-bottom: 110px">
        <h2 class="mb-8">Hunger bekommen?</h2>
        <p class="mb-8">Finde heraus, ob wir auch in dein Gebiet liefern!</p>
        <br />
        <button class="mdButton" @click="scrollToSearch">Lass checken</button>
      </v-col>
    </v-row>

    <ImageAndTextRow
      :imgURL="imgTextRowImg"
      :imgWidth="imgTextRowImgWidth"
      :imgHeight="imgTextRowImgHeight"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'margin-bottom: 70px;'
          : 'margin-bottom: 170px;'
      "
    />

    <!--  LegalLine Wrapper  -->
    <div id="aloha"></div>

    <v-row no-guters>
      <v-col cols="12" style="margin-bottom: 40px">
        <h2 class="mb-8" style="font-size: 36px; line-height: 46px">
          Häufig gestellte Fragen
        </h2>
        <p>Hier findest du Hilfe</p>
        <br />
      </v-col>
    </v-row>

    <!-- FAQs-->
    <AccordionComponent
      :accordion-content="faqsMainPage"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'margin-bottom: 40px;'
          : 'margin-bottom: 80px;'
      "
    />

    <v-col cols="12" class="legal-lines">
      <span
        >Coca-Cola ist eine eingetragene Schutzmarke der The Coca-Cola Company.
        © {{ printCurrentYear() }} McDonald’s</span
      >
      <!--<span><sup>1</sup> Dieser Gutschein kann eingelöst werden, indem der jeweilige Code beim Bezahlvorgang auf lieferando.de oder in der mobilen Lieferando-App eingegeben wird. Der verbleibende Betrag muss mit einer der verfügbaren Online-Zahlungsmethoden beglichen werden. Der Gutscheincode kann nur einmal verwendet werden und ist nur für Neukunden einlösbar. Gutscheine dürfen nicht verkauft werden und können nicht ausgezahlt oder mit anderen Gutscheinen kombiniert werden. Lieferando behält sich das Recht vor, den Gutschein im Falle von Betrug oder Missbrauch zu stornieren, so dass er nicht mehr eingelöst werden kann. Gültig bis zum 31.12.2025 bei McDonald's. Limitiert auf 1000 Einlösungen.</span>-->
    </v-col>
  </v-container>
</template>

<script>
import TeaserImage from "@/components/TeaserImage";
import SearchComponent from "@/components/SearchComponent";
import BoxesImageText from "@/components/BoxesImageText";
import CarouselSlider from "@/components/CarouselSlider";
import AccordionComponent from "@/components/AccordionComponent";
import ImageAndTextRow from "@/components/ImageAndTextRow";

export default {
  name: "MainPage",
  components: {
    TeaserImage,
    SearchComponent,
    BoxesImageText,
    CarouselSlider,
    AccordionComponent,
    ImageAndTextRow,
  },
  data: (_this) => ({
    legalLineContent: "", // Initialisierung des Inhalts der Legal Line

    instructionStepsBoxes: [
      {
        image: _this.getImgURL("home/McDelivery-Step-01.webp"),
        header: "Schritt 1",
        text: "Gib deine PLZ ein und finde heraus, ob McDelivery® dort grundsätzlich verfügbar ist.",
        altTag: "McDelivery Postleitzahlen Eingabe",
      },
      {
        image: _this.getImgURL("home/McDelivery-Step-02.webp"),
        header: "Schritt 2",
        text: "Nun werden dir in deiner Nähe verfügbare Lieferdienste angezeigt. Wähle einen Lieferdienst, gib deine genaue Adresse an und erfahre, ob auch deine Straße innerhalb des Liefergebietes liegt.",
        altTag: "McDelivery Lieferdienst wählen",
      },
      {
        image: _this.getImgURL("home/McDelivery-Step-03.webp"),
        header: "Schritt 3",
        text: "Schließe deine Bestellung ab. Dann heißt es nur noch: McDonald’s Lieferung abwarten und genießen!",
        altTag: "McDelivery Bestellung abschließen und genießen",
      },
    ],
    bottomBoxes: [
      {
        image: _this.getImgURL("home/McDelivery-Teaser-01.webp"),
        header: "Egal wo. Egal wann.",
        text: "Du möchtest eine kurze Lernpause in der Uni einlegen, bei der Hausparty groß auftischen oder leidest an plötzlicher Koch-Unlust? Lass uns liefern!",
        altTag: "McDelivery fröhliche McDonald’s Fans",
      },
      {
        image: _this.getImgURL("home/McDelivery-Teaser-02.webp"),
        header: "Egal was…",
        text: "Burger, Apfeltaschen oder auch nur ein Wasser: Bestell, was dich bockt. In unserem Liefersortiment gibt es (fast) keine Einschränkungen!",
        altTag: "McDelivery Produktbild",
      },
    ],
    faqsMainPage: [
      {
        header: "Wie finde ich heraus, ob McDonald’s zu mir liefert?",
        content:
          "Prüfe unter mcdelivery.de ob deine Postleitzahl im Liefergebiet ist. Auf der Seite des jeweiligen Lieferdienstes (Lieferando, Uber Eats oder Wolt) kannst du dann deine genaue Adresse prüfen. Bitte beachte: Wir arbeiten stets daran unser Liefergebiet noch weiter auszubauen. Solltest du also derzeit leider noch nicht beliefert werden können, schau gerne bald wieder rein.",
      },
      {
        header: "Wer bringt das Essen?",
        content:
          "Geliefert wird entweder von unseren eigenen Lieferfahrerinnen und -fahrern, oder von unseren Partnern Lieferando, Uber Eats oder Wolt.",
      },
      {
        header: "Wie kann man etwas bestellen?",
        content:
          "Alle Informationen zur Bestellung findest du hier auf mcdelivery.de bzw. auf den Seiten unserer Lieferpartner.",
      },
      {
        header: "Wie lange dauert die Lieferung?",
        content:
          "Die genaue Lieferzeit hängt von vielen Faktoren ab (Uhrzeit, Lieferweg, etc.) Die geschätzte Dauer wird dir aber im Rahmen der Bestellung angezeigt.",
      },
      {
        header:
          "Kann ich alle Produkte bestellen, die es auch im Restaurant gibt?",
        content:
          "Uns ist es wichtig, dass eure liebsten McDonald’s Produkte unseren hohen Qualitätsstandards entsprechen und in bester Qualität bei euch ankommen. Manche unserer Produkte eignen sich allerdings nicht ganz so gut für eine Lieferung. Unser McDelivery-Angebot wurde deshalb entsprechend angepasst. So liefern wir Getränke beispielsweise in PET-Flaschen aus, da sich diese hierfür besser eignen.",
      },
      {
        header: "Gibt es spezielle McDelivery®-Angebote?",
        content:
          "Ja, beispielsweise haben wir tolle Menü-Angebote passend zu vielen Anlässen. Egal ob für deine Date-Night oder ein leckeres Familienessen – wir haben das passende Angebot für dich.",
      },
      {
        header: "Wann wird geliefert?",
        content:
          "Unsere Restaurants haben unterschiedliche Lieferzeiten. Genaue Informationen dazu findest du auf den Restaurantseiten unserer Lieferpartner (Lieferando, Uber Eats oder Wolt). Bitte beachte: zu Stoßzeiten kann es vorkommen, dass einzelne Restaurants ausgelastet sind und vorübergehend eine Bestellung nicht möglich ist. In diesem Fall schau am besten ein bisschen später nochmal rein.",
      },
      {
        header:
          "Wo kann man sich melden, wenn etwas mit der Bestellung nicht geklappt hat oder man mit dem Service nicht zufrieden ist?",
        content:
          "Wende dich bei Fragen rund um die Belieferung direkt an das Support Team des Lieferpartners, bei dem du bestellt hast (Lieferando, Uber Eats oder Wolt). Bei allen Fragen, die unsere Produkte betreffen, wende dich gerne an unseren <a href='https://www.mcdonalds.com/de/de-de/kontakt.html' style='color: #292929; text-decoration: underline'>McDonald’s Gästeservice</a>.",
      },
      {
        header:
          "Kann ich Coupons aus der McDonald’s App auch bei McDelivery® einlösen?",
        content: "Dies ist leider aktuell noch nicht möglich.",
      },
    ],
    jsonLdData: [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "McDelivery® | McDonald’s Lieferservice",
        url: "https://mcdelivery.de/",
        logo: "https://mcdelivery.de/SocialShareImg-default.png",
      },
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [],
      },
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        image: [],
      },
    ],
  }),
  mounted() {
    this.scrollToTop();
    this.addJsonLdToHead();
  },
  methods: {
    onLegalLineDataAvailable(legalLine) {
      // Füge den generierten Inhalt der legalLine in die Datenoption legalLineContent ein
      this.legalLineContent = legalLine;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    // goToCokeAndWin() {
    //   router.push({name: 'CokeAndWin'})
    // },
    scrollToSearch: function () {
      setTimeout(() => {
        var element = document.getElementById("search");
        var headerOffset = 0;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, 10);
    },
    printCurrentYear() {
      return new Date().getFullYear();
    },
    getImgURL(path) {
      return process.env.VUE_APP_IMG_URL + path;
    },
    getFAQSchema() {
      let mainEntities = [];
      this.faqsMainPage?.forEach((faq) => {
        let entity = {
          "@type": "Question",
          name: faq.header,
          acceptedAnswer: {
            "@type": "Answer",
            text: faq.content,
          },
        };
        mainEntities.push(entity);
      });
      this.jsonLdData[1].mainEntity = mainEntities;
    },
    getImgSchema() {
      let images = [];
      images.push({
        "@type": "ImageObject",
        contentUrl: this.mainTeaserImg,
        width: this.mainTeaserImgWidth,
        height: this.mainTeaserImgHeight,
      });
      this.instructionStepsBoxes?.forEach((box) => {
        let image = {
          "@type": "ImageObject",
          contentUrl: box.image,
          width: this.instructionImgWidth,
          height: this.instructionImgHeight,
        };
        images.push(image);
      });
      this.slideImgs?.forEach((img) => {
        let image = {
          "@type": "ImageObject",
          contentUrl: img,
          width: this.slideImgWidth,
          height: this.slideImgHeight,
        };
        images.push(image);
      });
      this.bottomBoxes?.forEach((box) => {
        let image = {
          "@type": "ImageObject",
          contentUrl: box.image,
          width: this.boxImgWidth,
          height: this.boxImgHeight,
        };
        images.push(image);
      });
      images.push({
        "@type": "ImageObject",
        contentUrl: this.imgTextRowImg,
        width: this.imgTextRowImgWidth,
        height: this.imgTextRowImgHeight,
      });
      this.jsonLdData[2].image = images;
    },
    async addJsonLdToHead() {
      await this.getFAQSchema();
      await this.getImgSchema();
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(this.jsonLdData);
      document.head.appendChild(script);
    },
    removeJsonLdFromHead() {
      document.head.querySelectorAll("script").forEach((script) => {
        if (script.type === "application/ld+json") {
          script.remove();
        }
      });
    },
  },
  computed: {
    mainTeaserImg() {
      return this.$vuetify.breakpoint.xs
        ? this.getImgURL("home/McDelivery-Header-Monopoly-Mobile.webp")
        : this.getImgURL("home/McDelivery-Header-Monopoly-Desktop.webp");
    },
    mainTeaserImgWidth() {
      return this.$vuetify.breakpoint.xs ? 575 : 1144;
    },
    mainTeaserImgHeight() {
      return this.$vuetify.breakpoint.xs ? 449 : 509;
    },

    instructionImgWidth() {
      return this.$vuetify.breakpoint.xs ? 581 : 373;
    },
    instructionImgHeight() {
      return this.$vuetify.breakpoint.xs ? 391 : 251;
    },

    slideImgs() {
      return [
        this.$vuetify.breakpoint.xs
          ? this.getImgURL("carousel/McDelivery-Slide-01-mobile.webp")
          : this.getImgURL("carousel/McDelivery-Slide-01-desktop.webp"),
        this.$vuetify.breakpoint.xs
          ? this.getImgURL("carousel/McDelivery-Slide-02-mobile.webp")
          : this.getImgURL("carousel/McDelivery-Slide-02-desktop.webp"),
        this.$vuetify.breakpoint.xs
          ? this.getImgURL("carousel/McDelivery-Slide-03-mobile.webp")
          : this.getImgURL("carousel/McDelivery-Slide-03-desktop.webp"),
      ];
    },
    slideImgWidth() {
      return this.$vuetify.breakpoint.xs ? 573 : 1144;
    },
    slideImgHeight() {
      return this.$vuetify.breakpoint.xs ? 255 : 508;
    },

    boxImgWidth() {
      return this.$vuetify.breakpoint.xs ? 581 : 568;
    },
    boxImgHeight() {
      return this.$vuetify.breakpoint.xs ? 386 : 378;
    },

    imgTextRowImg() {
      return this.getImgURL("home/McDelivery-Teaser-03.webp");
    },
    imgTextRowImgWidth() {
      return this.$vuetify.breakpoint.xs ? 575 : 560;
    },
    imgTextRowImgHeight() {
      return this.$vuetify.breakpoint.xs ? 435 : 424;
    },
  },
  beforeDestroy() {
    this.removeJsonLdFromHead();
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  max-width: 1168px;
}

.teaser-image {
  margin-bottom: 80px;

  &.mobile {
    margin-bottom: 20px;
  }
}

.info-header-1 {
  margin-bottom: 80px;
  text-align: center;

  &.mobile {
    margin-bottom: 20px;

    h3 {
      font-size: 20px;
      line-height: 1.3;
    }
  }
}

.legal-lines {
  > span {
    display: block;
    font-size: 11px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
}
</style>
