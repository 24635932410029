<template>
  <v-row class="carousel-slider">
    <v-col>
      <v-carousel
        class="carousel-component"
        :class="[
          $vuetify.breakpoint.smAndDown ? 'mobile' : '',
          $vuetify.breakpoint.sm ? 'tabletOnly' : '',
        ]"
        :style="$vuetify.breakpoint.sm ? 'border: 1px solid #CECECE;' : ''"
        hide-delimiters
        v-model="currentSlide"
        :cycle="cycle"
        :continuous="true"
        :show-arrows="false"
        ref="carousel"
        interval="8000"
      >
        <v-carousel-item
          class="carousel-item"
          v-for="(slide, i) in slides"
          :key="i"
          reverse-transition="none"
          transition="none"
        >
          <div
            class="slide-item-wrapper"
            :style="$vuetify.breakpoint.xs ? 'border: 1px solid #CECECE;' : ''"
          >
            <img
              class="slide-image"
              :width="slideImgWidth"
              :height="slideImgHeight"
              :src="slideImgs[i]"
              :alt="slide.altTag"
              loading="lazy"
            />
            <div
              class="text-and-controls-wrapper"
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'pt-4 pr-8 pb-4 pl-8'
                  : 'pt-6 pr-8 pb-6 pl-8'
              "
            >
              <h2 v-html="slide.header" class="mb-2"></h2>
              <p v-html="slide.text"></p>

              <div
                class="slider-controls"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-8'"
              >
                <!--PREV SLIDE-->
                <v-btn icon color="black" @click="slidePrev">
                  <v-icon class="slider-dot" :color="'#292929'"
                    >mdi-chevron-left</v-icon
                  >
                </v-btn>

                <!--DOTS CONTOLS-->
                <div class="slider-dots">
                  <v-btn
                    v-for="(slide, i) in slides"
                    :key="i"
                    icon
                    color="#292929"
                    @click="currentSlide = i"
                  >
                    <v-icon
                      class="slider-dot"
                      :color="currentSlide === i ? '#292929' : '#949494'"
                      >mdi-circle-medium</v-icon
                    >
                  </v-btn>

                  <!--NEXT SLIDE-->
                  <v-btn icon color="black" @click="slideNext">
                    <v-icon class="slider-dot" :color="'#292929'"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                </div>
                <span class="mr-4 ml-3" style="color: #292929">|</span>

                <!--              PLAY / PAUSE BUTTON-->
                <div class="play-pause-buttons-wrapper">
                  <button v-if="!cycle" @click="cycle = true">
                    <v-icon color="#292929">mdi-play</v-icon>
                    Wiedergabe
                  </button>
                  <button v-if="cycle" @click="cycle = false">
                    <v-icon color="#292929">mdi-pause</v-icon>
                    Anhalten
                  </button>
                </div>
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
// import Vue from 'vue'

export default {
  name: "CarouselSlider",
  props: ["boxContent", "slideImgWidth", "slideImgHeight", "slideImgs"],
  mounted() {
    // console.log(this.$refs.carousel)
    // const VBtn = Vue.component('VBtn')
    // VBtn.options.props.ripple.default = false
  },
  data: () => ({
    currentSlide: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    slides: [
      {
        header: "Special Deals",
        text: "McDelivery® liefert dir nicht nur dein Lieblingsessen, sondern auch exklusive Angebote, wie unser Family Bundle oder unser Couple Menü.",
        altTag: "McDelivery Special Deals",
      },
      {
        header: "Schnell und bequem",
        text: "Ob mit eigener McDelivery® Flotte oder einem Lieferdienst: Sobald die Produkte sorgfältig verpackt wurden, macht sich deine Bestellung schnellstmöglich auf den Weg zu dir. Für dich heißt es dann nur noch: Füße hoch und warten. ",
        altTag: "McDelivery Schnell und bequem",
      },
      {
        header: "Qualität wie im Restaurant",
        text: "Damit dein Lieblingsessen in gewohnter Qualität auf deinen Tisch kommt, testen wir jedes Produkt auf Transportfähigkeit. Daher kann es sein, dass einige wenige Produkte nicht im McDonald’s Lieferservice angeboten werden, um unseren Qualitätsstandards gerecht zu werden.",
        altTag: "McDelivery Qualität wie im Restaurant",
      },
    ],
    cycle: true,
  }),
  methods: {
    slideNext() {
      this.$refs.carousel.next();
    },
    slidePrev() {
      this.$refs.carousel.prev();
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.carousel-slider {
  margin-bottom: 110px;
}

.carousel-item {
  width: 100%;
}

.slide-image {
  max-width: 100%;
  height: auto;
}

.slider-controls {
  display: block;
}

.slider-dots {
  display: inline;
}

.play-pause-buttons-wrapper {
  display: inline-block;
}

.text-and-controls-wrapper {
  max-width: 436px;
  position: absolute;
  display: block;
  bottom: 3%;
  left: 20px;
  background: #ffffff;
  box-shadow: 0 1.5px 1.5px 0 rgb(0 0 0 / 8%);
  border-radius: 4px;
}

//MOBILE CSS
.carousel-component.mobile {
  border-radius: 0 0 4px 4px;

  .text-and-controls-wrapper {
    position: static;
    max-width: none;
  }
}

.carousel-component.mobile.tabletOnly {
  .text-and-controls-wrapper {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.v-ripple__container {
  display: none !important;
}
</style>
