<template>
  <v-row justify="center" class="image-and-text-row">
    <v-col cols="12" sm="6">
      <img
        class="teaser-img"
        :width="imgWidth"
        :height="imgHeight"
        :src="imgURL"
        alt="McDelivery Lieferfahrer"
        loading="lazy"
      />
    </v-col>

    <v-col class="text-column" cols="12" sm="6">
      <div>
        <h2 class="mb-6">Du bist voll der Bringer?</h2>
        <p class="mb-6">
          Wir suchen jemanden, der unsere Gäste mit ihrem Lieblingessen zusammen
          bringt. Wenn du also gern unterwegs, offen und ein Team-Player bist,
          dann ist Lieferfahrer:in bei McDonald’s das richtige für dich.
        </p>
        <a
          href="https://karriere.mcdonalds.de/jobangebote/22/?mtm_campaign=EBR22&mtm_source=mcdelivery.de&mtm_medium=Banner&mtm_group=Crew"
          class="mdButton"
          >Jetzt bewerben</a
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ImageAndTextColumns",
  props: ["boxContent", "imgURL", "imgWidth", "imgHeight"],
  data: () => ({}),
  methods: {
    getImgURL(path) {
      return process.env.VUE_APP_IMG_URL + path;
    },
  },
  computed: {
    dynamicCols() {
      if (this.boxContent.length <= 2) {
        return 6;
      } else if (this.boxContent.length <= 3) {
        return 4;
      } else {
        return 3;
      }
    },
  },
};
</script>

<style scoped>
.text-column {
  display: flex;
  align-items: center;
}

.image-and-text-row .teaser-img {
  max-width: 100%;
  height: auto;
}
</style>
